import React from "react";
import YouTube from "react-youtube";

export default function VideoPlayer({ videoId, width }) {

  const height = (width * 450) / 800; // Calculando a altura proporcional

  const opts = {
    width: width,
    height: height.toString()
  };

  return (
    <YouTube videoId={videoId} opts={opts}/>
  );
}
