import React from "react";

import './CardAreas.modules.scss';
import { Link } from "react-router-dom";

export default function CardAreas({ dark, img, titulo, texto, endereco }) {

    return (
        <Link className={`cardAreas ${dark? 'dark' : ''}`} to={endereco}>
            <div className="imagem">
                <img src={img} alt="" />
                <div className="detalheImagem"></div>
            </div>
            <div className="info">
                <h2>{titulo}</h2>
                <p>{texto}</p>
                <button>Saiba Mais</button>
            </div>
        </Link>
    );
};