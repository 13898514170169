import React from 'react';
import './Form.modules.scss'
import InputMask from 'react-input-mask';

/* import sendMail from '../BannerHome/index' */

export default function Form({ light }) {

    return (

        <form
            action="https://formsubmit.co/gabriel.lopes.work@outlook.com" 
            method='POST'
            className='form'
        >
            <div className='Collum'>
                <label className={light ? "labelMaisFina" : ''} htmlFor="nome">Nome:</label>
                <input
                    className={light ? "white" : ''}
                    type="text"
                    name='nome'
                    id='nome'
                    placeholder='Digite seu nome...'
                    autoComplete='name'
                    required
                />
            </div>
            <div className='form__two row'>
                <div className='form__two-email Collum'>
                    <label className={light ? "labelMaisFina" : ''} htmlFor="email">E-mail:</label>

                    <input
                        className={light ? "white" : ''}
                        type="email"
                        name='email'
                        id='email'
                        placeholder='exemplo@email.com'
                        autoComplete='email'
                        required
                    />
                </div>

                <div className='form__two-tel Collum'>

                    <label className={light ? "labelMaisFina" : ''} htmlFor="tel">Telefone:</label>

                    <InputMask 
                        id='tel' 
                        name='telefone' 
                        mask="(99) 99999-9999" 
                        maskChar=" " 
                        autoComplete='tel'
                    >
                        {(inputProps) => <input
                            className={light ? "white" : ''}
                            placeholder='(XX) XXXXX-XXXX'
                            required
                            type="text"
                            {...inputProps}
                        />}
                    </InputMask>
                </div>
            </div>
            <div className='Collum'>
                <label
                    className={light ? "labelMaisFina" : ''}
                    htmlFor="message">Mensagem:
                </label>

                <textarea
                    id='message'
                    name='message'
                    style={{
                        minWidth: "100%",
                        maxWidth: "100%",
                        maxHeight: '200px',
                        minHeight: '130px',
                    }}
                    rows={4}
                    className={`textArea ${light ? "white" : ''}`}
                    placeholder='Digite aqui sua mensagem...'
                />
            </div>

            <button className={`form__btn ${light ? "btnWhite" : ''}`}>Enviar</button>

        </form>
    )
};