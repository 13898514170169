import React from 'react';
import { NavLink } from 'react-router-dom';
import './Menu.modules.scss';

export default function Menu({ home}) {

    const isActiveLink = (link) => {
        /* console.log(window.location.pathname) */
        return window.location.pathname === link ? 'link-active' : '';
    };

    return (
        <>
            <ul className="menu">
                {home ? 
                <>
                    <li><NavLink to="/" className={`link-menu home`}>Home</NavLink></li> 
                    <li><NavLink to="/a-clinica" className={`link-menu home ${isActiveLink('/a-clinica')}`}>A Clínica</NavLink></li>
                    <li><NavLink to="/area-de-atuacao" className={`link-menu home ${isActiveLink('/area-de-atuacao')}`}>Áreas De Atuação</NavLink></li>
                    <li><NavLink to="/blog" className={`link-menu home ${isActiveLink('/blog')}`}>Blog</NavLink></li>
                    <li><NavLink to="/contato" className={`link-menu home ${isActiveLink('/contato')}`}>Contato</NavLink></li>
                </>
                    :
                <>
                    <li><NavLink to="/" className={`link-menu ${isActiveLink('/')}`}>Home</NavLink></li>
                    <li><NavLink to="/a-clinica" className={`link-menu ${isActiveLink('/a-clinica')}`}>A Clínica</NavLink></li>
                    <li><NavLink to="/area-de-atuacao" className={`link-menu ${isActiveLink('/area-de-atuacao')}`}>Áreas de atuação</NavLink></li>
                    <li><NavLink to="/blog" className={`link-menu ${isActiveLink('/blog')}`}>Blog</NavLink></li>
                    <li><NavLink to="/contato" className={`link-menu ${isActiveLink('/contato')}`}>Contato</NavLink></li>
                </>
                }
            </ul>
        </>
    );
};

