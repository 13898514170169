import React from "react";
import '../GoogleFeedback.modules.scss'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';
import iconeGoogle from 'assets/icones/google.png'

export default function CardGoogle({ img, nome, data, star5, star4halp, star4, texto }) {

    return (
        <>
            <div className="swiperGoogle_slide_divTop">
                <div className="swiperGoogle_slide_divTop_dados">
                    <img src={img} alt="foto de perfil" />

                    <div>
                        <h3>{nome}</h3>
                        <span>{data}</span>
                    </div>
                </div>
                <img src={iconeGoogle} alt="icone Google" />
            </div>

            <div className="swiperGoogle_slide_estrelas">
                {star5 ?
                    <>
                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                        <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                    </>
                    : star4halp ?
                        <>
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                            <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                            <FontAwesomeIcon icon={faStarHalf} style={{ color: "#FFD43B" }} />
                        </>
                        : star4 ?
                            <>
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                            </>
                            :
                            <>
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                                <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B" }} />
                            </>
                }
            </div>

            <p>{texto}</p>
        </>
    );
};