import React from "react"
import styles from './Footer.module.scss'
import Logo from "componentes/Logo"

//icones
import locationIcone from 'assets/icones/mapaBranco.png'
/* import locationIcone from 'assets/icones/mapas-e-bandeiras.png' */
import whatsDourado from 'assets/icones/whatsBranco.png'
import instagramIcone from 'assets/icones/instaBranco.png'
import emailIcone from 'assets/icones/emailBranco.png'
import horarioIcone from 'assets/icones/relogio2.png'

export default function Footer() {
    return (
        <section className={styles.footer}>
            <div className={styles.footer_marca}>
                <Logo /* aMao */ semFundo />

                <div className={styles.footer_marca__p}>
                    <p>Prof. Dr. Valdelias Xavier Pereira</p>
                    <p>CRO.SP 00001</p>
                </div>
            </div>

            <div className={styles.footer_itens}>
                <div className={`${styles.footer_endereco} ${styles.footer_div}`}>
                    <div className={styles.footer_subTitulo}>
                        <h3>Localização</h3>
                        <hr />
                    </div>

                    <div className={styles.footer_divConteudo}>
                        <div className={styles.footer_divConteudo__item}>
                            <span>
                            <img className={styles.footer_divConteudo_img} src={locationIcone} alt="" />
                                Rua Jean Anastace Kovelis, 1167 - Portais (Polvilho),
                                Cajamar-SP, 07791-803.</span>
                        </div>
                    </div>
                </div>

                <div className={`${styles.footer_horario} ${styles.footer_div}`}>
                    <div className={styles.footer_subTitulo}>
                        <h3>atendimento</h3>
                        <hr />
                    </div>

                    <div className={styles.footer_divConteudo}>
                        <p>Horário de atendimento:</p>

                        <div className={styles.footer_divConteudo__item}>
                            <img className={styles.footer_divConteudo_img} id="relogio" src={horarioIcone} alt="" />
                            <span>
                                De segunda à sexta das 08h até 18h.</span>
                        </div>
                    </div>
                </div>

                <div className={`${styles.footer_contato} ${styles.footer_div}`}>
                    <div className={styles.footer_subTitulo}>
                        <h3>Contato</h3>
                        <hr />
                    </div>

                    <div className={styles.footer_divConteudo}>
                        <div className={styles.footer_divConteudo__item}>
                            <img className={styles.footer_divConteudo_img} src={whatsDourado} alt="" />
                            <span>(11) 94130-7034</span>
                        </div>

                        <div className={styles.footer_divConteudo__item}>
                            <img className={styles.footer_divConteudo_img} src={instagramIcone} alt="" />
                            <span>@dreliasxavier</span>
                        </div>

                        <div className={styles.footer_divConteudo__item}>
                            <img className={styles.footer_divConteudo_img} src={emailIcone} alt="" />
                            <span>valdeliasxavierpereira@gmail.com</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={styles.footer_footer}>
                <p>Prof. Dr. Valdelias Xavier Pereira</p>
                <p>CRO.SP 00001</p>
            </div>
        </section>
    )
};