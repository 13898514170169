import React from "react";
import './FaixaLocation.modules.scss'
import { Link } from "react-router-dom";

export default function FaixaLocation({ titulo, subtitulo, urlSubtitulo }) {
    return (
        <section className="faixaLocation">
            <Link to={urlSubtitulo}>
                <span className="faixaLocation__span">{subtitulo}</span>
            </Link>
            <h1>{titulo}</h1>
        </section>
    )
}