import React from "react";
import { Link } from "react-router-dom";
import './BtnContato.modules.scss'

export default function BtnContato({ urlDestino, texto, tamanho}) {
    return (
        <Link to={urlDestino}>
            <button style={{fontSize: tamanho}} className="btnContato">
                {texto}
            </button>
        </Link>
    )
}