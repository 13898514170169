import React from 'react';
import './Contato.modules.scss'

import NavBar from 'componentes/NavBar';
import Form from 'componentes/Form';
/* import Footer from 'componentes/Footer';
import Copyright from 'componentes/Copyright'; */
import WhatsAppButton from 'componentes/WhatsAppButtom';
import RedesSociais from 'componentes/RedesSociais';
import Footer from 'componentes/Footer';
import Copyright from 'componentes/Copyright';

export default function Contato() {

    return (
        <>
            <header>
                <NavBar />
            </header>

            <main className='main-contato'>
                <section className="contato">
                    <h2 className="contato__titulo">Contato</h2>
                    {/* <p>*imagem com direitos autorais</p> */}
                </section>

                <section className="info">
                    <h1 className="info_h1">Envie sua Mensagem</h1>
                    <hr className='linha' />
                    <p className="info_texto">Por meio deste formulário é possível tirar
                        dúvidas sobre os nossos serviços e iniciar seu agendamento. Se 
                        preferir, entre em contato pelo nosso whatsapp clicando no
                        botão ao lado.</p>
                </section>

                <section className="contato-container">
                    <div className="left">
                        <Form />
                    </div>
                    <div className="right">
                        <div className='right__meiosDeContato'>
                            <h3>Meios de contato</h3>
                            
                            <RedesSociais  original fundoBranco/>
                        </div>
                    </div>
                </section>

                <section className="contato-mapa">
                        <div className="iframe-container">
                            {/* <h3>Localização</h3> */}
                            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3523.1448034575415!2d-46.856572815205396!3d-23.407718250239633!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94cf1d1a6ca639a9%3A0x1c4d16dc10997294!2sR.%20Jean%20Anastace%20Kovelis%2C%201167%20-%20Jardim%20Adelaide%2C%20Cajamar%20-%20SP%2C%2007750-000!5e0!3m2!1spt-BR!2sbr!4v1713805393992!5m2!1spt-BR!2sbr"
                                width="100%"
                                height="350px"
                                style={{ border: 'none' }}
                                loading="lazy"
                                title="Mapa da Localização"
                                referrerPolicy="no-referrer-when-downgrade"></iframe>                        
                        </div>

                </section>

                <WhatsAppButton
                    phoneNumber="5511941307034"
                    mensagem="Olá, gostaria de mais informações!"
                />
            </main>

            <footer>
                <Footer />
                <Copyright />
            </footer>
        </>
    );
};