import React from 'react';
import './CardPrevArtigo.modules.scss'

import artigos from 'json/ArtigosData.json'
import { Link } from 'react-router-dom';

function CardsRelacionados({ artigosRelacionados }) {
    return (
        <div className='cardPrev'>
            {/* Mapeando os artigos relacionados e renderizando cada um */}
            {artigosRelacionados.map(artigo => (
                <Link key={artigo.id} to={`/blog/${artigo.caminho}`}>
                    <div className='cardPrev__item'>
                        <img src={artigo.img} alt={artigo.titulo} />
                        <h2>{artigo.titulo}</h2>
                        {/* Adicione qualquer outro conteúdo que você queira exibir para cada artigo */}
                    </div>
                </Link>
            ))}
        </div>
    );
}

export default CardsRelacionados;