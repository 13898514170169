import React from 'react';
import './Logo.modules.scss'
import logo from 'assets/logo/logoOrofacial.png'
import logoRecortado from 'assets/logo/logoRecortado.png'
import { Link } from 'react-router-dom';

export default function Logo({ semFundo, fonteLight, branco, aMao }) {
    return (
        <div className="logo">
            <>
                {semFundo ?
                    <Link to="/" className="logo__imgRecortado">
                        <img src={logoRecortado} alt="Logo" />
                    </Link>
                    :
                    <Link to="/" className="logo__img">
                        <img src={logo} alt="Logo" />
                    </Link>
                }

                {fonteLight ?
                    <Link to="/" className="logo__home logo-texto">
                        <h2>Orofacial Studio</h2>
                    </Link>
                    : branco ?
                    <Link to="/" className="logo__Branco logo-texto">
                        <h2>Orofacial Studio</h2>
                    </Link>
                    : aMao ?
                    <Link to="/" className="logo__aMao logo-texto">
                        <h2>Orofacial Studio</h2>
                    </Link>
                    :
                    <Link to="/" className="logo__name logo-texto">
                        <h2>Orofacial Studio</h2>
                    </Link>
                }
            </>
        </div>
    );
};