import React from "react";
import './Copyright.scss'

import logoGabbsDev from 'assets/copyright/logoRecortada.png'
import { Link } from "react-router-dom";

export default function Copyright() {

    const RedirectGabbsDev = () => {
        window.open(`https://www.gabbsdev.com`, '_blank');
    };

    return (
        <div className="copy">
             <Link to="/privacy-policy">Política de Privacidade</Link>
            <p>&copy; Copyright - Todos os direitos reservados.</p>
            <div className="copy_gabbsDev">
                Made by
                <img src={logoGabbsDev} alt="logo Gabbs Dev" onClick={RedirectGabbsDev}/>
            </div>
        </div>
    )
};
