import React from "react";
import './GoogleFeedback.modules.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/autoplay';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar, faStarHalf } from '@fortawesome/free-solid-svg-icons';

//imagem
import logoGoogle from 'assets/imagens/logos/google.png'

//imgs Perfil
import imgTeste from './imagensPerfil/imgTeste.png'
import CardGoogle from "./CardGoogle";

export default function GoogleFeedback() {

    const navigationStyles = {
        width: '50px', // Define a largura das setas
        height: '50px', // Define a altura das setas
        backgroundColor: 'transparent',
        color: '#FFFFFF71',
        fontSize: '30px' // Define o tamanho do ícone das setas
    };

    return (
        <section className="google">
            <h2 className="google_titulo">Avaliações de nossos clientes</h2>
            <p className="google_subtitulo">Feedback de pacientes após a realização de 
            nossos serviços e atendimento.</p>
            <div className="google_divAvaliação">
                <h3>Excelente</h3>
                <div className="google_divAvaliação__estrelas">
                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", width: "30px" }} />
                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", width: "30px" }} />
                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", width: "30px" }} />
                    <FontAwesomeIcon icon={faStar} style={{ color: "#FFD43B", width: "30px" }} />
                    <FontAwesomeIcon icon={faStarHalf} style={{ color: "#FFD43B", width: "30px" }} />
                </div>
                <span>Com base em <b>27 Avaliações</b></span>
                <img className="logoGoogle" src={logoGoogle} alt="logo do Google" />
            </div>

            <Swiper
                className="swiperGoogle"
                modules={[Navigation, Pagination, Autoplay, Scrollbar, EffectFade]}
                spaceBetween={5}
                slidesPerView={3}
                loop={false}
                /* pagination={{ clickable: true }} */
                /* scrollbar={{ draggable: false }} */
                autoplay={{ 
                    delay: 1000,
                    disableOnInteraction: false,
                    waitForTransition: true
                }}

                speed={10000}
                /* onSwiper={(swiper) => console.log(swiper)} */
                /* onSlideChange={() => console.log('slide change')} */
                navigation={{
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                }}
            >
                <SwiperSlide className="swiperGoogle_slide">
                    <CardGoogle 
                        img={imgTeste} 
                        nome="Stefanny Apolinaki Lemos" 
                        data="22-03-2024"
                        star5
                        texto="Adorei o procedimento, estou encantada."
                    />
                </SwiperSlide>

                <SwiperSlide className="swiperGoogle_slide">
                    <CardGoogle 
                        img={imgTeste} 
                        nome="Felipe Macedo dos Santos" 
                        data="27-02-2024"
                        star5
                        texto="Ficou top! adorei."
                    />
                </SwiperSlide>

                <SwiperSlide className="swiperGoogle_slide">
                    <CardGoogle 
                        img={imgTeste} 
                        nome="Rafael Bernardo da Silva" 
                        data="06-01-2024"
                        star4halp
                        texto="Profissionais incriveis, me senti acolhido!"
                    />
                </SwiperSlide>

                <SwiperSlide className="swiperGoogle_slide">
                    <CardGoogle 
                        img={imgTeste} 
                        nome="Mariana Oliveira Abiake" 
                        data="27-02-2024"
                        star4halp
                        texto="amei o resultado, ótimos doutores."
                    />
                </SwiperSlide>

                <div className="swiper-button-prev" style={navigationStyles}></div>
                <div className="swiper-button-next" style={navigationStyles}></div>
            </Swiper>


        </section>
    );
};