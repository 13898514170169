import React from "react";
import './RedesSociais.modules.scss'

//icones dourados
import whatsIcon from 'assets/icones/whats.png'
import instaIcon from 'assets/icones/instagram.png'
import youtubeIcon from 'assets/icones/youtubeDegrade.png'
import emailIcon from 'assets/icones/correspondencia.png'

//icones originais
import instaOriginal from 'assets/icones/instagramOriginal.png'
import whatsOriginal from 'assets/icones/WhatsApp.png'
import youtubeOriginal from 'assets/icones/youtube.png'
import gmailOriginal from 'assets/icones/gmail2.png'
import mapa from 'assets/icones/mapa.png'
/* import localizacao2 from 'assets/icones/localizacao2.png' */


export default function RedesSociais({ original, fundoBranco }) {

    const RedirectWhatsapp = () => {
        window.open(`https://wa.me/5511941307034?text="Olá, gostaria de mais informações!"`, '_blank');
    };

    const RedirectInstagram = () => {
        window.open('https://www.instagram.com/dreliasxavier', '_blank');
    };

    const RedirectYoutube = () => {
        window.open('https://www.youtube.com/results?search_query=Orofacial+Studio', '_blank');
    };

    const RedirectLocalizacao = () => {
        window.open('https://www.google.com/maps/place/R.+Jean+Anastace+Kovelis,+1167+-+Portais+(Polvilho),+Cajamar+-+SP,+07791-803/@-23.4081427,-46.8572341,17z/data=!4m6!3m5!1s0x94cf1d1a4262921d:0x458fa999e8b7685e!8m2!3d-23.4078765!4d-46.8556088!16s%2Fg%2F11y45jznj9?hl=pt-BR&entry=ttu', '_blank');
    };

    /* const RedirectEmail = () => {
        window.open('https://www.instagram.com/dreliasxavier', '_blank');
    }; */

    const insta = original ? instaOriginal : instaIcon
    const whats = original ? whatsOriginal : whatsIcon
    const youtube = original ? youtubeOriginal : youtubeIcon
    const email = original ? gmailOriginal : emailIcon

    return (
        <div className="redesSociais">
            <div className="redesSociais__item" onClick={RedirectWhatsapp}>
                <img src={whats} alt="icone whatsapp" />
                <span className={fundoBranco ? 'maisGrosso' : ''}>(11) 99807-7190</span>
            </div>

            <div className="redesSociais__item" onClick={RedirectInstagram}>
                <img src={insta} alt="icone instagram" />
                <span className={fundoBranco ? 'maisGrosso' : ''}>@dreliasxavier</span>
            </div>

            <div className="redesSociais__item" onClick={RedirectYoutube}>
                <img src={youtube} alt="icone youtube" />
                <span className={fundoBranco ? 'maisGrosso' : ''}>Orofacial Studio</span>
            </div>

            <div className="redesSociais__item" /* onClick={RedirectEmail} */>
                <img src={email} alt="icone email" />
                <span className={fundoBranco ? 'maisGrosso' : ''}>valdeliasxavierpereira@gmail.com</span>
            </div>

            <div className="redesSociais__item" onClick={RedirectLocalizacao}>
                <img src={mapa} alt="icone mapa" />
                <span className={fundoBranco ? 'maisGrosso' : ''}>Rua Jean Anastace Kovelis, 1167 Portais (Polvilho), Cajamar-SP.</span>
            </div>
        </div>
    )
}