import React from "react";
import './CardArtigo.modules.scss'
import { Link } from "react-router-dom";

export default function CardArtigo({ artigo }) {
    /* console.log(artigo) */
    return (
        <Link to={`/blog/${artigo.caminho}`}>
            <div className="cardArtigo">
                <div className="cardArtigo__img">
                    <img src={artigo.img} alt="imagem card" />
                    <span>{artigo.data}</span>
                </div>

                <div className="cardArtigo__info">
                    {/* <span>Leitura 13min</span> */}
                    <h2>{artigo.titulo}</h2>
                    <p>{artigo.subTitulo}</p>
                    {/* <p>Confira o artigo na integra por <b>aqui.</b></p>
                    <span>Leia mais --</span> */}
                </div>
            </div>
        </Link>
    );
};


/* 

*/