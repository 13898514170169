import React from "react";

import './SwiperClinicaHome.modules.scss'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, Autoplay, EffectFade } from 'swiper/modules';

//imagens
import img1 from 'assets/imagens/especialistas-em-protese-protocolo-campinas_.jpg'

export default function SwiperCLinicaHome() {
    return (
        <Swiper 
            className="swiperClinicaHome"
            modules={[Navigation, Pagination, Scrollbar, EffectFade]}
            spaceBetween={10}
            slidesPerView={3}
            loop={true}
            >

            <SwiperSlide className="swiperClinicaHome_slide"><img className="" src={img1} alt="img1" /></SwiperSlide>
            <SwiperSlide className="swiperClinicaHome_slide"><img className="" src={img1} alt="img2" /></SwiperSlide>
            <SwiperSlide className="swiperClinicaHome_slide"><img className="" src={img1} alt="img3" /></SwiperSlide>
            <SwiperSlide className="swiperClinicaHome_slide"><img className="" src={img1} alt="img1" /></SwiperSlide>
            <SwiperSlide className="swiperClinicaHome_slide"><img className="" src={img1} alt="img2" /></SwiperSlide>
            <SwiperSlide className="swiperClinicaHome_slide"><img className="" src={img1} alt="img3" /></SwiperSlide>

            <div className="swiper-button-prev"></div>
            <div className="swiper-button-next"></div>
        </Swiper>
    );
};