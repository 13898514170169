import React, { useState, useEffect } from 'react';
import './WhatsApp.modules.scss';

import iconeWhatsApp from 'assets/icones/whatsBranco.png';

const WhatsAppButton = ({ phoneNumber, mensagem }) => {
  const [isVibrating, setVibrating] = useState(false);

  const message = encodeURIComponent(mensagem);
  const handleClickWhats = () => {
    // Lógica para abrir o WhatsApp com o número de telefone fornecido
    window.open(`https://wa.me/${phoneNumber}?text=${message}`, '_blank');
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setVibrating(prevState => !prevState); // Alternar o estado
      setTimeout(() => {
        setVibrating(false); // Defini o estado como false dps do tempo
      }, 2000);
    }, 7000); // 5000ms de intervalo inativo + 2000ms de intervalo ativo
  
    return () => clearInterval(interval); // Limpar o intervalo
  }, []);

  return (
    <div className={`div-btn ${isVibrating ? 'vibrating' : ''}`}>
      <button
        className={`whats-btn`}
        onClick={handleClickWhats}
        >
        <img src={iconeWhatsApp} alt="icone WhatsApp" />

        <div className='whats-btn__notificacao'>
          1
        </div>
      </button>
    </div>
  );
};

export default WhatsAppButton;