import React from 'react';
import './FotosSection.modules.scss'

//imagens 
import img1 from 'assets/imagens/imgClinica1.jpg'
import img2 from 'assets/imagens/imgClinica2.jpg'

function FotosSection() {
  // Array 
  const fotos = [
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
    img1,
    img2,
  ];

  return (
    <section className='fotos'>
      <div className='grid-container'>
        {fotos.map((foto, index) => (
          <div key={index} className='foto-item'>
            <img src={foto} alt={`Foto ${index + 1}`} />
          </div>
        ))}
      </div>
    </section>
  );
}

export default FotosSection;