import React from 'react';
import './Sobre.modules.scss';

import NavBar from 'componentes/NavBar';
import Footer from 'componentes/Footer';
import Copyright from 'componentes/Copyright';

//imagens
import img2 from 'assets/imagens/dentistas-em-campinas23_.jpg'
import img1 from 'assets/imagens/especialistas-em-protese-protocolo-campinas_.jpg'
import doutor from 'assets/imagens/doutor1.jpg'

//logo
import logoRecortado from 'assets/logo/logoRecortado.png'

// icones
import missao from 'assets/icones/missao.png'
import visao from 'assets/icones/visao.png'
import valores from 'assets/icones/valor.png'
import WhatsAppButton from 'componentes/WhatsAppButtom';
import BannerPages from 'componentes/BannerPages';
import FotosSection from 'componentes/FotosSection';

export default function Sobre() {

    return (
        <>
            <header>
                <NavBar />
            </header>

            <main>
                <section className='clinica'>
                    <h2 className="clinica__titulo">
                        clínica odontológica</h2>
                    {/* <div className='clinica__front'></div> */}
                </section>

                <section className="sobre">
                    <div className='sobre__imagens'>
                        <img src={img1} alt="" />
                        <img className='sobre__imagens_two' src={img2} alt="" />
                    </div>

                    <div className="sobre__texto">
                            <h1>Conheça a Clínica</h1>
                            <hr />
                        <div className="sobre__texto_txt">
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Quam facere labore incidunt magni ullam
                                facilis voluptatum rerum illo minima at reiciendis
                                similique repellat hic minus mollitia laborum
                                veritatis obcaecati, necessitatibus officia cumque
                                optio asperiores? laborum iure placeat consequuntur
                                impedit magni temporibus repellat quisquam quasi
                                autem exercitationem corporis? Fuga minima aliquid
                                numquam laudantium? Officiis voluptatibus porro nam
                                recusandae dicta reiciendis eaque, soluta repellat
                                sapiente fugit ullam laboriosam.</p>

                            <p>Lorem ipsum dolor sit amet consectetur adipisicing
                                elit. Quam facere labore incidunt magni ullam
                                facilis voluptatum rerum illo minima at reiciendis
                                similique repellat hic minus mollitia laborum
                                veritatis obcaecati, necessitatibus officia cumque
                                optio asperiores? Accusantium nisi ad at velit
                                excepturi fugit.</p>
                        </div>
                    </div>
                </section>

                <section className="empresa">
                    <div className='empresa__container'>
                        <div className="card" id="card-1">
                            <img src={missao} alt="icone de alvo" />
                            <h2>Missão</h2>
                            <p>Prover soluções para a saúde bucal com excelência
                                e rapidez buscando o bem-estar de nossos pacientes.</p>
                        </div>

                        <div className="card" id="card-2">
                            <img src={visao} alt="icone de binoculos" />
                            <h2>Visão</h2>
                            <p>Ser referência como clínica Odontológica de
                                especialistas, com o melhor e mais adequado
                                tratamento.</p>
                        </div>

                        <div className="card" id="card-3">
                            <img src={valores} alt="icone de mao segurando pessoas" />
                            <h2>Valores</h2>
                            <p>Ter excelência, respeito, voltado ao paciente,
                                inovação, integridade e qualidade de vida.</p>
                        </div>
                    </div>
                </section>

                <section className="corpoClinico">
                    <h1 className="corpoClinico_titulo">Corpo Clínico</h1>
                    <span>Conheça os responsáveis técnicos da Orofacial Studio</span>
                    <div className="corpoClinico_Div">
                        <div className="corpoClinico_Div__txt">
                            <h1>Prof. Dr. Valdelias Xavier Pereira</h1>
                            {/* <hr /> */}
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.
                                Laudantium architecto eos non placeat! Alias ea
                                perspiciatis voluptates debitis laborum vel, cupiditate,
                                impedit laboriosam non sunt deleniti molestias magnam
                                maxime voluptatum delectus corporis reiciendis odio
                                cumque porro expedita modi unde. Recusandae reiciendis
                                nisi nihil necessitatibus dignissimos adipisci nostrum
                                harum voluptatem nam.</p>
                        </div>

                        <div className="corpoClinico_Div__img">
                            <img
                                src={doutor}
                                alt="imagem responsável técnico"
                                className="imgDoutor" />
                        </div>
                    </div>
                </section>

                <FotosSection />

                <WhatsAppButton
                    phoneNumber="5511941307034"
                    mensagem="Olá, gostaria de mais informações!"
                />
            </main>

            <footer>
                <Footer />
                <Copyright />
            </footer>
        </>
    );
};