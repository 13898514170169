import React from 'react'
import { Link } from 'react-router-dom'

import '../Home/Home.modules.scss'

import BannerHome from 'componentes/BannerHome'
import WhatsAppButton from 'componentes/WhatsAppButtom'
import Footer from 'componentes/Footer'
import Copyright from 'componentes/Copyright'
import GoogleFeedback from 'componentes/GoogleFeedback'
import BtnContato from 'componentes/BtnContato'
import SwiperCLinicaHome from 'componentes/SwiperClinicaHome'
import CardArtigo from 'componentes/CardArtigo'

import imgCard1 from 'assets/imagens/lentes2.jpg'
import ContatoSection from 'componentes/ContatoSection'

import artigos from 'json/ArtigosData.json'

export default function Home() {

  const idDosArtigos = [1, 7];

  // Filtrando os artigos com base nos IDs
  const artigosFiltrados = artigos.filter(artigo => idDosArtigos.includes(artigo.id));

  const renderCards = artigosFiltrados.map((artigo, index) => (
    <CardArtigo key={index} artigo={artigo} />
  ));


  return (
    <>
      <header className="header">
        <BannerHome />
      </header>

      <main className='main'>
        <section className='aClinicaHome'>
          <div className='aClinicaHome_div'>
            <div className='aClinicaHome_conheca'>
              <h1>Conheça a clínica</h1>
              <hr />
              <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit.
                Itaque minima explicabo sint libero consequuntur molestiae
                expedita blanditiis, quaerat dolorum accusantium.</p>

              <BtnContato texto="Saiba Mais" urlDestino="a-clinica" tamanho="18px" />
            </div>

            <div className='aClinicaHome_swiper'>
              <SwiperCLinicaHome />
            </div>
          </div>

        </section>

        <section className="servicosHome">
          <h1>Conheça nossas áreas de atuação</h1>

          <h2>Volte a sorrir!</h2>

          <div className='servicosHome__container'>
            <Link
              className='item item1'
              to="area-de-atuacao/implantodontia">
              <div className="item_conteudo">
                <h2>Implantodontia</h2>
                <p>Substituição de dentes ausentes com implantes de titânio
                  para restaurar função e estética.</p>
              </div>
            </Link>

            <Link className='item item2' to="area-de-atuacao/lentes-de-contato-dental">
              <div className="item_conteudo">
                <h2>Lentes de Contato Dental</h2>
                <p>Aplicação de finas camadas sobre dentes para corrigir
                  imperfeições estéticas.</p>
              </div>
            </Link>

            <div className='item item3'>
              <div className="item_conteudo" id='itemCoringa'>
                <h2>Cirurgia Maxilofacial</h2>
                <p>Correção de deformidades faciais, remoção de tumores e
                  cistos da região facial e bucal.</p>
              </div>
            </div>

            <div className='item item4'>
              <div className="item_conteudo">
                <h2>Ortodontia</h2>
                <p>Correção de dentes desalinhados e problemas de mordida
                  para melhorar estética e função.</p>
              </div>
            </div>

            <div className='item item5'>
              <div className="item_conteudo">
                <h2>Harmonização OroFacial</h2>
                <p>Procedimentos estéticos faciais para equilíbrio e
                  melhoria da aparência e função da face.</p>
              </div>
            </div>
          </div>
        </section>

        {/* <ContatoSection /> */}

        <section className='blogResumo'>
          <div className='blogResumo__container'>
            <div className='blogResumo__container-info'>
              <h1>Blog</h1>
              <hr />

              <p>Lorem ipsum dolor sit, amet consectetur adipisicing 
                elit. Quas eaque nemo quo aspernatur recusandae 
                necessitatibus rerum optio id omnis amet?</p>
              <BtnContato texto="Conheça nosso Blog"/>
            </div>

            <div className='blogResumo__container-cards'>
              {renderCards}
            </div>
          </div>
        </section>

        <ContatoSection />

        <GoogleFeedback />

        <WhatsAppButton
          phoneNumber="5511941307034"
          mensagem="Olá, gostaria de mais informações!"
        />
      </main>

      <footer>
        <Footer />
        <Copyright />
      </footer>
    </>
  )
}
