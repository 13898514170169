import React from 'react'
import './BannerHome.modules.scss'
import Carousel from 'componentes/Carousel'
import NavBar from 'componentes/NavBar'

export default function BannerHome() {
  return (
    <section className="banner">
      <NavBar />
      <Carousel />
    </section>
  )
}
