import React from 'react';

function NotFound() {
  return (
    <div>
      <h1>Página não encontrada</h1>
      <p>A página que você está procurando não foi encontrada.</p>
    </div>
  );
};

export default NotFound;