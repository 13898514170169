import NavBar from 'componentes/NavBar';
import React from 'react';

import './AreasDeAtuacao.modules.scss';
import CardAreas from 'componentes/CardAreas';

//imagens
import imgImplantodontia from 'assets/imagens/implantes2.jpg'
import imgLentes from 'assets/imagens/lentes2.jpg'
import imgCirugia from 'assets/imagens/cirurgia2.jpg'
import imgOrtodontia from 'assets/imagens/ortodontia2.jpg'
import imgHarmonizacao from 'assets/imagens/botox2.jpg'
import Footer from 'componentes/Footer';
import Copyright from 'componentes/Copyright';
import FaixaLocation from 'componentes/FaixaLocation';

export default function AreasDeAtuacao() {
    return (
        <>
            <header>
                <NavBar />
            </header>

            <main className='mainAreas'>
                <FaixaLocation titulo="Áreas de Atuação" subtitulo="Conheça nossos serviços" />

                <section className='AreasDeAtuacao'>
                    <CardAreas
                        img={imgLentes}
                        titulo="Lentes de Contato Dental"
                        texto="Aplicação de finas camadas sobre dentes para corrigir
                        imperfeições estéticas."
                        endereco="lentes-de-contato-dental"
                    />

                    <CardAreas
                        img={imgCirugia}
                        titulo="Cirugia Maxilofacial"
                        texto="Correção de deformidades faciais, remoção de tumores e
                        cistos da região facial e bucal."
                        endereco="cirurgia-maxilofacial"
                        dark
                    />

                    <CardAreas
                        img={imgImplantodontia}
                        titulo="Implantodontia"
                        texto="Implantes dentários utilizando as mais 
                        avançadas técnicas disponíveis, em procedimentos 
                        minimamente invasivos."
                        endereco="implantodontia"
                    />
                    <CardAreas
                        img={imgOrtodontia}
                        titulo="Ortodontia"
                        texto="Correção de dentes desalinhados e problemas de mordida
                        para melhorar estética e função."
                        endereco="ortodontia"
                        dark
                    />

                    {<CardAreas
                        img={imgHarmonizacao}
                        titulo="Harmonização Orofacial"
                        texto="Procedimentos estéticos faciais para equilíbrio e
                        melhoria da aparência e função da face."
                        endereco="harmonizacao-orofacial"
                    />}
                </section>
            </main>

            <footer>
                <Footer />
                <Copyright />
            </footer>
        </>
    );
};