import NavBar from "componentes/NavBar";
import React from "react";
import '../AreasPages.modules.scss'

//imagem principal
import imgPrincipal from 'assets/imagens/implantes.jpg'
import imgSecundaria from 'assets/imagens/implantes2.jpg'

import BtnContato from "componentes/BtnContato";
import Footer from "componentes/Footer";
import Copyright from "componentes/Copyright";
import VideoPlayer from "componentes/VideoPlayer";
import FaixaLocation from "componentes/FaixaLocation";

export default function ImplantodontiaPage() {
    return (
        <>
            <header>
                <NavBar />
            </header>

            <main className="mainArea">
                <FaixaLocation 
                    subtitulo="Áreas de Atuação" 
                    urlSubtitulo="/area-de-atuacao" 
                    titulo="Implantodontia"
                />

                <section className="area-sobre">
                    <div className="area-sobre__left">
                        <h1>O que é Implante?</h1>
                        <hr />

                        <p>As lentes de contato de porcelana são finas camadas de 
                            material cerâmico que são colados à superfície do dente 
                            conseguindo transformar cor, tamanho e formato e chegam 
                            a durar em torno de 30 anos e não precisam de manutenção, 
                            apenas limpeza de rotina.</p>

                        <p><b>A quem é indicado?</b> É indicado para correção de cor, 
                        tamanho e formato dos dentes em várias situações.</p>

                        <p><b>Como é Feito?</b> Trata-se da aplicação de finas camadas 
                        de resina sobre os dentes, dando nova cor, tamanho e formato. 
                        Nesta técnica os dentes são esculpidos à mão livre.</p>

                        <BtnContato texto="Agende uma consulta" urlDestino="/contato"/>
                    </div>

                    <div className="area-sobre__right">
                        <img className="img-principal" src={imgPrincipal} alt="imagem de lentes de contato dental" />

                        <img className="img-secundaria" src={imgSecundaria} alt="imagem de ortodontia" />
                        
                        <div className="area-sobre__right-detalheImg"></div>
                    </div>
                </section>

                <section className="area-mais">
                    <div className="video">
                        <VideoPlayer videoId="c5HYg5nNK2o" width="600"/>
                    </div>
                    <div className="comoEhFeito">
                        <h1>Como é feito?</h1>
                        <hr />

                        <p>São necessárias 3 consultas para que a mesma possa ser 
                            realizada. Geralmente na consulta inicial o profissional 
                            scaneia o paciente, monta o planejamento do novo sorriso, 
                            na segunda consulta realiza os desgastes necessários nos 
                            dentes, que são super conservadores.</p>
                    </div>
                </section>
            </main>

            <footer>
                <Footer />
                <Copyright />
            </footer>
        </>
    )
};