import React from 'react';
import { useParams } from 'react-router-dom';

import ReactMarkdown from 'react-markdown';

import NavBar from 'componentes/NavBar';
import img1 from '../imagensBlog/dentadura.jpg'

import './Artigo.modules.scss'
import FaixaLocation from 'componentes/FaixaLocation';

import artigosData from 'json/ArtigosData.json'
import NotFound from 'pages/NotFound';
import CardsRelacionados from 'componentes/CardsRelacionados/indes';

function ArtigoPage() {

    const parametros = useParams()

    const caminho = parametros['*']

    const artigo = artigosData.find((artigo) => {
        return artigo.caminho === caminho
    })


    if (!parametros || !artigo.img) {
        return <NotFound />
    }

    /* const artigosRelacionados = artigosData.filter(art => art.id !== artigo.id).slice(0, 5); */
    const artigosRelacionados = findRelatedArticles(artigo, artigosData, 5);

    console.log(artigo.img)

    return (
        <>
            <header>
                <NavBar />
            </header>

            <main className='mainArtigo'>
                {/* <FaixaLocation subtitulo={`Blog > ${artigo.titulo}artigo`} titulo={artigo.titulo}/> */}
                <section className='artigo'>
                    <div className="artigo__conteudo">
                        <img src={artigo.img} alt="imagem artigo" />
                        <h1>{artigo.titulo}</h1>
                        <div className='markdown-container'>
                            <ReactMarkdown>
                                {artigo.texto}
                            </ReactMarkdown>
                        </div>
                    </div>
                    <div className="artigo__menuLateral">
                        <h2>Posts Relacionados</h2>

                        <CardsRelacionados artigosRelacionados={artigosRelacionados} />
                    </div>
                </section>
            </main>
        </>
    );
}

function findRelatedArticles(artigoAtual, todosArtigos, maxResultados) {
    const artigosRelacionados = [];
    const palavrasChaveArtigoAtual = new Set(artigoAtual.palavrasChave);

    
    for (const artigo of todosArtigos) {
        /* console.log(artigo.palavrasChave) */
        if (artigosRelacionados.length < maxResultados && artigo.palavrasChave && Array.isArray(artigo.palavrasChave)) {
            if (artigo.id !== artigoAtual.id && artigo.palavrasChave.some((palavraChave) => palavrasChaveArtigoAtual.has(palavraChave))) {
                artigosRelacionados.push(artigo);
            }
        }
    }

    // Se não houver artigos suficientes correspondentes, preenche com os primeiros artigos não relacionados
    if (artigosRelacionados.length < maxResultados) {
        for (const artigo of todosArtigos) {
            if (artigosRelacionados.length === maxResultados) {
                break;
            }
            if (artigo.id !== artigoAtual.id && !artigosRelacionados.includes(artigo)) {
                artigosRelacionados.push(artigo);
            }
        }
    }

    return artigosRelacionados;
}

export default ArtigoPage;