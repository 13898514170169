import React, { useEffect, useState, useRef } from 'react';
import './MenuHamburguer.modules.scss'

// icone
import menuDourado from 'assets/icones/menu-dourado.png'
import menuBranco from 'assets/icones/menu-branco.png'
import { NavLink } from 'react-router-dom';

function MenuHamburguer({ dourado }) {
    const [menuAberto, setMenuAberto] = useState(false);
    const menuRef = useRef();

    useEffect(() => {
        function handleClickOutside(event) {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMenuAberto(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setMenuAberto(!menuAberto);
        console.log(menuAberto)
    };

    return (
        <div className={`menuHamburguer`} ref={menuRef}>
            <img src={dourado ? menuDourado : menuBranco} alt="ícone de menu" onClick={toggleMenu} />
            {menuAberto && (
                <ul className={`menuLista ${menuAberto === true ? 'open' : ''}`}>
                    <hr />
                    <li><NavLink to="/" className='menuLista__link'>Home</NavLink></li>
                    <li><NavLink to="/a-clinica" className='menuLista__link'>A Clínica</NavLink></li>
                    <li><NavLink to="/area-de-atuacao" className='menuLista__link'>Areas de atuação</NavLink></li>
                    <li><NavLink to="/blog" className='menuLista__link'>Blog</NavLink></li>
                    <li><NavLink to="/contato" className='menuLista__link'>Contato</NavLink></li>
                </ul>
            )}
        </div>
    );
}

export default MenuHamburguer;