import React from 'react';
import CardArtigo from 'componentes/CardArtigo';
import './PaginaBlog.modules.scss'

const PaginaAtualBlog = ({ artigos, currentPage, cardsPerPage, onPageChange }) => {
  // Lógica para calcular o índice inicial e final dos artigos na página atual
  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = artigos.slice(indexOfFirstCard, indexOfLastCard);

  console.log(currentPage)

  // Renderização dos cards na página atual
  const renderCards = currentCards.map((artigo, index) => (
    <CardArtigo key={index} artigo={artigo} />
  ));
  console.log(currentCards)

  // Lógica para criar os números das páginas e gerenciar a navegação entre elas
  const pageNumbers = [];

  console.log(artigos.length)
  for (let i = 1; i <= Math.ceil(artigos.length / cardsPerPage); i++) {
    pageNumbers.push(i);
  }

  return (
    <>
      <div className="grid-container">{renderCards}</div>
      <div className="pagination">
        {pageNumbers.map(number => (
          <button key={number} 
            onClick={() => onPageChange(number)}
            className={number === currentPage ? 'current-page' : ''}
          >
            {number}
          </button>
        ))} 
      </div>
    </>
  );
};

export default PaginaAtualBlog;
