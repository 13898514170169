import React from "react";
import './NavBar.modules.scss'

import { Link } from "react-router-dom";
import Menu from "componentes/Menu";
import Logo from "componentes/Logo";
import MenuHamburguer from "componentes/MenuHamburguer";
import iconeYoutube from 'assets/icones/youtube.png'

export default function NavBar() {
    return (
        <nav className="NavBar">
            <Logo semFundo branco/>
            <Menu />
            <MenuHamburguer/>
                <Link className="NavBar_youtube" target="_blank" to='https://www.youtube.com/results?search_query=orofacial+studio'>
                    <img src={iconeYoutube} alt="" />
                    <h4>Orofacial Studio</h4>
                </Link>
        </nav>
    )
};