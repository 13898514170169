import React from "react";
import './ContatoSection.modules.scss'
import Form from "componentes/Form";
import Logo from "componentes/Logo";
import RedesSociais from "componentes/RedesSociais";

export default function ContatoSection() {
    return (
        <section className="ContatoSection">
            <h2 className="ContatoSection_titulo">Duvidas? Gostou dos nossos serviços e deseja agendar?</h2>
            <h3 className="ContatoSection_subTitulo">Entre em contato abaixo</h3>
            <div className="ContatoSection__container">
                <div className="ContatoSection__container_left">
                    <h3>Envie uma mensagem:</h3>
                    <Form light/>
                </div>

                <div className="ContatoSection__container_right">
                    <Logo semFundo/>
                    <hr />
                    <h3>Redes Sociais</h3>
                    <RedesSociais original />
                </div>
            </div>
                
        </section>
    )
};