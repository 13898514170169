import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "pages/NotFound";
import Sobre from "pages/Sobre";
import Blog from "pages/Blog";
import Contato from "pages/Contato";
import AreasDeAtuacao from "pages/AreasDeAtuacao";
import ImplantodontiaPage from "pages/AreasDeAtuacao/AreasPages/Implatodontia";
import LentesPage from "pages/AreasDeAtuacao/AreasPages/LentesDeContato";
import CirurgiaMaxiloFacialPage from "pages/AreasDeAtuacao/AreasPages/CirurgiaMaxiloFacial";
import OrtodontiaPage from "pages/AreasDeAtuacao/AreasPages/Ortodontia";
import HarmonizacaoPage from "pages/AreasDeAtuacao/AreasPages/Hamonizacao";
import ArtigoPage from "pages/Blog/Artigo";
import ScrollToTop from "componentes/ScrollToTop";


function AppRoutes() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/a-clinica" element={<Sobre />} />

        <Route path="/area-de-atuacao" element={<AreasDeAtuacao />} />
          <Route path="/area-de-atuacao/implantodontia" element={<ImplantodontiaPage />} />
          <Route path="/area-de-atuacao/lentes-de-contato-dental" element={<LentesPage />} />
          <Route path="/area-de-atuacao/cirurgia-maxilofacial" element={<CirurgiaMaxiloFacialPage />} />
          <Route path="/area-de-atuacao/ortodontia" element={<OrtodontiaPage />} />
          <Route path="/area-de-atuacao/harmonizacao-orofacial" element={<HarmonizacaoPage />} />

        <Route path="/blog" element={<Blog />} />
        <Route path="/blog/*" element={<ArtigoPage />} />
        <Route path="/contato" element={<Contato />} />

        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}


export default AppRoutes;
