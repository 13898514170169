import React, {useState} from 'react';

import FaixaLocation from 'componentes/FaixaLocation';
import NavBar from 'componentes/NavBar';

import './Blog.modules.scss'
import PaginaAtualBlog from './PaginaAtualBlog';
import artigosData from 'json/ArtigosData.json';

export default function Blog() {

    const [currentPage, setCurrentPage] = useState(1);
    const cardsPerPage = 12; // 3 colunas * 4 linhas

    const onPageChange = pageNumber => {
        console.log(pageNumber)
        setCurrentPage(pageNumber);
        window.scrollTo({
            top: 0,
            behavior: "smooth" // Adicionando a opção "smooth" para uma animação suave
          });
    };

    return (
        <>
            <header>
                <NavBar />
            </header>

            <main className="mainBlog">
                <FaixaLocation titulo="BLOG" subtitulo="Artigos e videos" />

                <section className='cards'>
                    <PaginaAtualBlog
                        artigos={artigosData}
                        currentPage={currentPage}
                        cardsPerPage={cardsPerPage}
                        onPageChange={onPageChange}
                    />
                </section>
            </main>
        </>
    );
};